import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import Translated from "../feature/Translated";
import ImageSet from "../feature/ImageSet";
import ActivityCount from "../feature/ActivityCount";
import { useGlobalData } from "../../context/GlobalContext";

const HomePage = () => {
  const { loggedOutMessage } = useGlobalData();
  const navigate = useNavigate();

  const logo = SERVICE_LOGO;
  const logoUrl = "../../assets/" + logo + ".png";

  return (
    <div id="home-page" className="front-center-wrapper">
      <div className="front-logo-wrapper">
        <ImageSet src={logoUrl} className="front-logo slide-in" alt="logo" />
        <br />
        <Translated
          className="front-slogan fade-in delay"
          translationKey="slogan"
        />
      </div>
      <div className="large-top-margin fade-in long-delay">
        <Button
          id="home-login-button"
          aria-label="Login"
          size="lg"
          className="horizontal-padding"
          onClick={() => navigate("login")}
          role="button"
        >
          <Translated
            className="strong-text"
            translationKey="login"
            aria-label="login"
          />
        </Button>
      </div>
      {loggedOutMessage && (
        <Alert className="front-logged-out-message"><Translated translationKey={loggedOutMessage} /></Alert>
      )}
      <div className="centered">
        <div className="inline extra-large-top-margin semi-dark-inner-box tight fade-in longer-delay">
          <ActivityCount type="large-number" />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
